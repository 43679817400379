import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import styled from 'styled-components';
import photo1 from '../img/redbull.jpeg';
import photo102 from '../img/redbull2.jpeg';
import photo103 from '../img/redbull3.jpeg';
import photo5 from '../img/rally2023.jpeg';
import photo6 from '../img/led-background.jpeg';
import photo2 from '../img/led-video.mp4';


import { Element } from 'react-scroll';
export default function Carocel() {
    return (
        <Element name="home">
            <Player autoPlay muted controls>
                <source src={photo2} type="video/mp4"  />
                    Seu navegador não suporta o elemento de vídeo.
            </Player>
            {/* <Container2>
            <ResponsiveIframe 
                     width="560" height="315"     src="https://www.youtube-nocookie.com/embed/aybNfxxvSU4?si=Wu8dA8UgsJOezJUE&amp;controls=0&amp;start=1&amp;rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
                </ResponsiveIframe>            </Container2> */}

            {/* <Container>
            <Splide
                options={{ pagination: true, arrows: true }}
                className="custom-splide"
            >
                <SplideSlide>
                    <img src={photo6} alt="img1" />
                </SplideSlide>
                <SplideSlide>
                    <img src={photo1} alt="img1" />
                </SplideSlide>
                <SplideSlide>
                    <img src={photo102} alt="img2" />
                </SplideSlide>
                <SplideSlide>
                    <img src={photo103} alt="img3" />
                </SplideSlide>
                <SplideSlide>
                    <img src={photo5} alt="img4" />
                </SplideSlide>
                <SplideSlide>
                    <img src={photo2} alt="img5" />
                </SplideSlide>
            </Splide>
        </Container> */}
        </Element>
    );
}
const Player = styled.video`
    margin-left: 20%;
    padding-top: 11vw;
    width: 60%;
    height: 78%;
    max-width: 1050px; /* Define uma largura máxima se desejar */
    aspect-ratio: 16 / 9; /* Mantém a proporção 16:9 */
    border: none;
    object-fit: cover; /* Garante que o vídeo preencha todo o espaço disponível */

`

const Container2 = styled.div`
    width: 90%;
    margin-left: 5%;
    height: 85vh;
    padding-top: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Adiciona overflow:hidden para esconder partes fora do contêiner */
    @media (max-width: 768px){
        height: 30%;
    }
`;

const Video = styled.div`
    width: 67%;
    height: 85%;
    max-width: 1050px; /* Define uma largura máxima se desejar */
    aspect-ratio: 16 / 9; /* Mantém a proporção 16:9 */
    border: none;
    object-fit: cover; /* Garante que o vídeo preencha todo o espaço disponível */

`;

const Container = styled.div`
    width: 100%;
    margin-top: 14vh;
    img {
        margin-top: 50px;
        width: 60vw;
        margin-left: 20%;
        height: 36vw;
    }
    @media(max-width: 1400px) {
        margin-top: 11vh;
    }
    @media (max-width: 768px) {
        img {
            margin-top: 5vh;
            width: 88vw;
            margin-left: 6%;
            height: 49vw;
        }
    }
`;