import styled from "styled-components"
import { Element } from "react-scroll"
import { FormattedMessage } from "react-intl"

export default function Profile() {

    const mapSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d406.94847564210073!2d136.9412340247828!3d35.31622224537061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60030d5f7121b4b7%3A0x41020d8e2aff0562!2z5ZCI5ZCM5Lya56S-44Ko44Or44Kk44O844OH44Kj44O844OT44K444On44Oz!5e0!3m2!1sen!2sjp!4v1719799731719!5m2!1sen!2sjp";


    return (<Element name="empresa">
        <Title><FormattedMessage id="app.profile" defaultMessage="Perfil da Empresa" /> </Title>
        <ProfileContainer>
            <TextContainer>
                <SubContainer>
                    <Text><FormattedMessage id="app.profile.text1" defaultMessage="Nome da Empresa" />  </Text> 
                    <Line/>
                    <Text1><FormattedMessage id="app.profile.text1.2" defaultMessage="Led Vision LLC" /></Text1>
                </SubContainer>
                <SubContainer>
                    <Text><FormattedMessage id="app.profile.text2" defaultMessage="Endereço" /> </Text> 
                    <Line/>
                    <Text1><FormattedMessage id="app.profile.text2.2" defaultMessage="166 Tagata-cho, Komaki, Aichi 485-0004" /> </Text1>
                </SubContainer>
                <SubContainer>
                    <Text>TEL.</Text> 
                    <Line/>
                    <Text1>0568-50-2233</Text1>
                </SubContainer>
                <SubContainer>
                    <Text><FormattedMessage id="app.profile.text4" defaultMessage="Diretor executivo" /></Text> 
                    <Line/>
                    <Text1><FormattedMessage id="app.profile.text4.2" defaultMessage="Emerson Hirano" /> </Text1>
                </SubContainer>    
                <SubContainer>
                    <Text><FormattedMessage id="app.profile.text5.3" defaultMessage="Data de fundação"  /></Text>
                    <Line/>
                    <Text1> <FormattedMessage id="app.profile.text5.4" defaultMessage="1º de abril de 2015" /></Text1>
                </SubContainer>
                <SubContainer>
                    <Text><FormattedMessage id="app.profile.text.5" defaultMessage="De microempresa para corporativo" /> </Text> 
                    <Line/>
                    <Text1><FormattedMessage id="app.profile.text.5.2" defaultMessage="8 de março de 2021" /></Text1>
                </SubContainer>
            
                <SubContainer>
                    <Text><FormattedMessage id="app.profile.text.6" defaultMessage="Capital social" /></Text> <Line/>
                    <Text1>3,000,000円</Text1>
                </SubContainer>
                <SubContainer>  
                    <Text><FormattedMessage id="app.profile.text.7" defaultMessage="Conteúdo comercial" /></Text> <Line/>
                    <Text1><FormattedMessage id="app.profile.text.7.2" defaultMessage="Aluguel e venda de equipamentos para eventos" /></Text1>
                </SubContainer>
                {/* <SubContainer2>  
                    <Text2>大型LEDビジョン</Text2> 
                    <Text2><FormattedMessage id="app.profile.text.9" defaultMessage="Caminhão de LED" /></Text2>
                    <Text2><FormattedMessage id="app.profile.text.10" defaultMessage="Iluminação de palco" /></Text2>
                    <Text2><FormattedMessage id="app.profile.text.11" defaultMessage="Sistema de som"/> </Text2>
                    <Text2><FormattedMessage id="app.profile.text.12" defaultMessage="Treliça de alumínio"  /></Text2>
                    <Text2><FormattedMessage id="app.profile.text.13" defaultMessage="Palco de alumínio"/>  </Text2>

                </SubContainer2> */}
            </TextContainer>

        </ProfileContainer>
        <Mapa>
      <Frame
        src={mapSrc}
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></Frame>
    </Mapa></Element>)
}

const Frame = styled.iframe`
width: 30vw;
height: 25vw;
@media(max-width:768px){
    width: 60vw;
    height: 50vw;
}
`

const Mapa = styled.div`
display: flex;
justify-content: center;
margin-bottom: 30px;
`

const Line = styled.div`
border: solid 1px black;
@media(max-width:768px){
    position: absolute;
    height: 78px;
    left: 50%;
}`

const SubContainer2 = styled.div`
display: flex;
flex-direction: column;
margin-left: 17.5vw;
margin-top: 20px;
@media(max-width:768px){
margin-top: 20px;
margin-left: 0px;
align-items: center;
margin-bottom: 10px;
}`

const Text1 = styled.p`
margin-top: 20px;
margin-left: 20px;
padding-left: 0px;
font-size: 20px;
@media(max-width:768px){
    margin-left: 0px;
    padding-left: 0px;
    position: absolute;
    left: 60%;
    font-size: 18px;
}
`

const SubContainer = styled.div`
display: flex;     
margin-left : 11vw ;
@media(max-width:768px){
    position: relative;
    height: 70px;
    margin-left: 0px;
}

`

const ProfileContainer = styled.div`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
margin-top: 50px;
padding-bottom: 50px;
`
const Title = styled.h1`
display: flex;
justify-content: center;
color: black;
margin-top: 30px;
font-size: 40px;
@media(max-width:768px){
    font-size: 20px;
}
`
const Text = styled.p`
width: 160px;
color: black;
margin-right: 30px ;
margin-top: 20px;
font-size: 20px;
@media(max-width:768px){
    font-size: 18px;
}`

const Text2 = styled.p`
margin-top: 10px;
color: black;
font-size: 20px;


`

const TextContainer = styled.div`
color: black;
display: flex;
flex-direction: column;
@media(max-width:768px){
    width : 85% ;
}

`