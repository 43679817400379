import styled from "styled-components"
import photo0 from '../img/festaphilipina.jpeg';
import photo1 from '../img/redbull.jpeg'
import photo102 from '../img/redbull2.jpeg'
import photo103 from '../img/redbull3.jpeg'
import photo2 from '../img/brazilianday2022.jpeg'
import photo3 from '../img/Festival Brasil.jpeg'
import photo4 from '../img/naolembro.jpeg'
import photo5 from '../img/rally2023.jpeg'
import photo6 from '../img/volei.jpeg'
import photo7 from '../img/okazaki.jpeg'
import photo701 from '../img/okazaki2.jpeg'
import photo8 from '../img/WRC2023.jpeg'
import photo9 from '../img/Central Japan Bodybuilding.jpeg'
import photo10 from '../img/Field Style.jpeg'
import photo11 from '../img/Camping Car Fair.jpeg'
import photo12 from '../img/Tribe Connection.jpeg'
import photo13 from '../img/Aichi Destination 2023.jpeg'
import photo14 from '../img/feira.jpeg'
import photo15 from '../img/festajunina.jpeg'
import photo16 from '../img/bancoDoBrasil.jpeg'
import photo17 from '../img/Japino Job Fair.jpeg'
import { useState, useContext } from "react";
import BackgroundContext from "../context/background.js";
import Picture from "./Picture.js";

export default function Photos() {
    const {poped,setPoped,selectedPhoto,setSelectedPhoto} = useContext(BackgroundContext)

    const photos = [
        [photo0, "Phil Fiesta Tokai 2019"], [photo1, photo102, photo103],
        [photo2, "ブラジリアンデージャパン浜松2022"], [photo3, "Festival Brasil 代々木公園"],
        [photo4, "豊田防災フェスタ"], [photo5, "新城ラリー2023"],
        [photo6, "Vリーグ"], [photo7, photo701, "岡崎花火大会"],
        [photo8, "WRCラリージャパン2023"], [photo9, "Central Japan Bodybuilding"],
        [photo10, "Field Style"], [photo11, "Camping Car Fair"],
        [photo12, "Tribe Connection"], [photo13, "Aichi Destination 2023"],
        [photo14, "展示会ブース"], [photo15, "フェスタジュニーナ豊橋"],
        [photo16, "ブラジル銀行イベントブース"], [photo17, "Japino Job Fair"]]

    function addPhotos() {
        return photos.map((photo, index) => (
            <li key={index}>
                <Img  onClick={()=>popupPhoto(index)}   src={photo[0]} />
            </li>
        ));
    }

    function popupPhoto(index) {
        console.log(index,"1")
        setPoped(true)
        setSelectedPhoto(index)

    }


    return (<>
        <Ul>
            {addPhotos()}
            {poped ?
                <Picture />
                :
                ""}
        </Ul></>)
}



const Ul = styled.ul`
position: relative;
display:grid;
grid-template-columns: repeat(6, 1fr);
column-gap: 2vw;
row-gap: 1.5vw;
margin-top: 5vh;
@media(max-width:1024px) {
    grid-template-columns: repeat(3, 1fr);

}
@media(max-width:768px){
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    margin-bottom: 15vw;
    ;
}
`


const Img = styled.img`
width: 160px;
height: 120px;
:hover{
        cursor: pointer;
    }
    @media(max-width:768px){
width: 100px;
height: 100px;
}
`