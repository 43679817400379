import styled from "styled-components"
import { Element } from 'react-scroll';
import { FormattedMessage } from "react-intl";


export default function AboutUs() {

    return (
        <Element name="about">
            <Container >
                <text><FormattedMessage id="app.about" defaultMessage="Sobre Nós" /> </text>
                <H1><FormattedMessage id="app.about.text" defaultMessage="Atuamos na área de aluguel e vendas de Painéis de Led, Equipamentos de Som e Iluminação.
                    Nosso objetivo é promover a interação entre o público e a mensagem proposta pelo nosso contratante,
                    seja em shows, palestras, e feiras. Com profissionalismo e atendimento personalizado, buscamos solucionar a
                    demanda de cada cliente de forma singular, para que cada apresentação seja vista de forma única." /> 
                </H1>

            </Container>
            </Element>)
}

const Container = styled.section`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text {
        font-size: 40px;
        color: black;
        font-weight: lighter;
    }
    @media (max-width: 768px) {
        width: 100vw;
    }
`;

const H1 = styled.h1`
    margin-top: 10px;
    color: black;
    width: 40%;
    font-size: 20px;
    font-weight: 200;
    @media (max-width: 768px) {
        width: 80%;
    }
`