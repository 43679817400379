import styled from "styled-components"

export default function Bottom() {
    return (
        <Margin>
            Copyright(c) LED Vision
        </Margin>
    )
}   

const Margin = styled.div`
    background-color: white;
    margin-top: 10vh;
    width: 100%;
    height: 2vh;
    justify-content: center;
    align-items: center;

`