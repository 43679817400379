import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';
import Wrapper from './context/Wrapper.js';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Wrapper  >
    <App />
    </Wrapper>
  </React.StrictMode>
);
