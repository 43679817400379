import styled from "styled-components"
import photo2 from '../img/brazilianday2022.jpeg'
import photo103 from '../img/redbull3.jpeg'
import photo5 from '../img/rally2023.jpeg'
import { Element } from 'react-scroll';
import { FormattedMessage } from "react-intl";

export default function Highlight() {

    return(
        <Element name="portfolio">
        <Portfolio>
            <text><FormattedMessage id="app.portfolio" defaultMessage="Portfolio"  /> 
            </text>
            <H1>
                <FormattedMessage id="app.portfolio.text" defaultMessage="Em nosso portfólio, contamos com serviços prestados a grandes empresas e corporações como Aichi TV ( Field Style, Camping Car Fair, Phil Fiesta Tokai),  Redbull, WRC Rally, Kowa Corporation, Banco do Brasil, entre outros." />
            </H1>
        <Container>
            <Item>
                <img src={photo2} alt />
                <h1>Brazilian Day Japan</h1>
                <Line />
                <p>
                    <FormattedMessage id="app.portfolio.text2" defaultMessage="Sendo o maior evento brasileiro realizado na Ásia, contando com um público estimado de 15 mil pessoas." /> </p>
            </Item>
            <Item>
                <img src={photo103} alt />
                <h1>Redbull</h1>
                <Line />
                <p>
                    <FormattedMessage id="app.portfolio.text3" defaultMessage=" Ação Promocional da Redbull realizado em Nagoya e Kobe" />
                    </p>
            </Item>
            <Item>
                <img src={photo5} alt />
                <h1>WRC Rally Japan</h1>
                <Line />
                <p> <FormattedMessage id="app.portfolio.text4" defaultMessage=" Maior evento da categoria realizado nos estados de Aichi e Gifu" /></p>
            </Item>


        </Container>
        </Portfolio>
        </Element>
    )

}
const H1 = styled.h1`
    margin-top: 10px;
    color: black;
    margin-left: 30%;
    width: 40%;
    font-size: 20px;
    font-weight: 200;
        margin-bottom: 2%;
    @media (max-width: 768px) {
        width: 80%;
        margin-left: 10%;
    }
`

const Portfolio = styled.div`
margin-top: 3%;
display: flex;
flex-direction: column;
    text {
        font-size: 40px;
        align-items: center;
        align-self: center;
        color: black;
    }
    @media (max-width: 768px) {
        width: 100vw;
        text{
        }
    }   
`

const Item = styled.div`
    position: relative;
    display: flex;
    padding: 10px;
    height: auto;
    margin-top: 10px;
    img{
        width: 50%;
        height: 50%;
        background-color: white;
        color: white;
    }
    h1{
        right: 17%;
        color: black;
        font-size: 30px;
        font-weight: 500;
        margin-left: 7%;
    }
    p{
        position: absolute;
        top: 20%;
        right: 8%;
        width: 35%;
        font-size: 20px;
        color: black;
        text-align: left;
    }
    @media(max-width: 1200px){
    img{
        margin-left: 10%;
        width: 40%;
        height: 20%;
    
    }
    }
    @media (max-width: 768px) {
        height: 40vh;
        img{
            margin-left: 10%;
            width: 80%;
            height: 50%;
        }
        h1{
            position: absolute;
            left: 12vw;
            top: 22vh;
            font-size: 1.5rem;
            margin-left: 0;
        }
        p{  
            position: absolute;
            top: 68%;
            left: 10%;
            width: 80%;
            font-size: 1.2rem;
        }
    }
`

const Container = styled.div`
    margin-left: 25%;
    width: 50%;
    @media(max-width: 1200px){
        margin-left: 0;
        width: 100%;
        margin-top: 5vh;
    }
    @media (max-width: 768px) {
        margin-left: 0;
        width: 100%;
        margin-top: 5vh;

    }
    `

const Line = styled.div`
    position: absolute;
    right: 8%;
    top: 12%;
    width: 35%;
    height: 1px;
    background-color: black;
    margin-top: 10px;
    @media (max-width: 768px) {
        right: 10vw;
        top: 60%;
        width: 80%;
    }
    `