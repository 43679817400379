import styled from "styled-components"
import { useContext, useState } from "react";
import BackgroundContext from "../context/background.js";
import photo0 from '../img/festaphilipina.jpeg';
import photo1 from '../img/redbull.jpeg'
import photo102 from '../img/redbull2.jpeg'
import photo103 from '../img/redbull3.jpeg'
import photo2 from '../img/brazilianday2022.jpeg'
import photo3 from '../img/Festival Brasil.jpeg'
import photo4 from '../img/naolembro.jpeg'
import photo5 from '../img/rally2023.jpeg'
import photo6 from '../img/volei.jpeg'
import photo7 from '../img/okazaki.jpeg'
import photo701 from '../img/okazaki2.jpeg'
import photo8 from '../img/WRC2023.jpeg'
import photo9 from '../img/Central Japan Bodybuilding.jpeg'
import photo10 from '../img/Field Style.jpeg'
import photo11 from '../img/Camping Car Fair.jpeg'
import photo12 from '../img/Tribe Connection.jpeg'
import photo13 from '../img/Aichi Destination 2023.jpeg'
import photo14 from '../img/feira.jpeg'
import photo15 from '../img/festajunina.jpeg'
import photo16 from '../img/bancoDoBrasil.jpeg'
import photo17 from '../img/Japino Job Fair.jpeg'
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaWindowClose } from "react-icons/fa";



export default function Picture() {
    const { poped, setPoped, selectedPhoto, setSelectedPhoto } = useContext(BackgroundContext)
    const [position, setPosition] = useState(0)
    const [position2,setPosition2] = useState(0)
    const photos = [
        [photo0, "Phil Fiesta Tokai 2019"], [photo1, photo102, photo103],
        [photo2, "ブラジリアンデージャパン浜松2022"], [photo3, "Festival Brasil 代々木公園"],
        [photo4, "豊田防災フェスタ"], [photo5, "新城ラリー2023"],
        [photo6, "Vリーグ"],
            [photo7,"岡崎花火大会", photo701, "岡崎花火大会"],
        [photo8, "WRCラリージャパン2023"], [photo9, "Central Japan Bodybuilding"],
        [photo10, "Field Style"], [photo11, "Camping Car Fair"],
        [photo12, "Tribe Connection"], [photo13, "Aichi Destination 2023"],
        [photo14, "展示会ブース"], [photo15, "フェスタジュニーナ豊橋"],
        [photo16, "ブラジル銀行イベントブース"], [photo17, "Japino Job Fair"]]

    const photoMulti = [photo1,photo102,photo103]
    const photoMulti2 =  [photo701, "岡崎花火大会"]
 

    function unclick() {
        setPoped(false)
    }
    function next() {
        if(position===2&&selectedPhoto===0){
            setPosition(0)
            setSelectedPhoto(1)
        } else if (selectedPhoto === 17) {
            setSelectedPhoto(0)
        } else {
            setSelectedPhoto(selectedPhoto + 1)
        }
    }
    function back() {
        if (selectedPhoto === 0) {
            setSelectedPhoto(17)
        } else {
            setSelectedPhoto(selectedPhoto - 1)
        }
    }
    function nextMulti() {
        if(position===2){
            setSelectedPhoto(2)
        }else{
        setPosition(position+1)}
    }
    function backMulti(){
            if(position===0){
                console.log(position)
                setSelectedPhoto(0)
            }else{
                setPosition(position-1)
            }
    }

    return (
        <>
            <Container onClick={() => unclick()}>
            </Container>
            <PopedImg >
                {selectedPhoto === 1 ?
                    <><Left onClick={() => backMulti()} >
                        <FaArrowAltCircleLeft />
                    </Left>
                        <img src={photoMulti[position]} />
                        <text>
                        </text>
                        <Close onClick={() => unclick()}>
                            <FaWindowClose />
                        </Close>
                        <Right onClick={() => nextMulti()}>
                            <FaArrowAltCircleRight />
                        </Right>
                    </>
                    :
                    <>
                        <Left onClick={() => back()} >
                            <FaArrowAltCircleLeft />
                        </Left>

                        {selectedPhoto===701?
                        <>
                        <img src={photoMulti2[0]}  />
                        <text>
                            {photoMulti2[1]}
                        </text>
                        </>:<></>}
                        <img src={photos[selectedPhoto][0]} />
                        <text>
                            {photos[selectedPhoto][1]}
                        </text>
                        <Close onClick={() => unclick()}>
                            <FaWindowClose />
                        </Close>
                        <Right onClick={() => next()}>
                            <FaArrowAltCircleRight />
                        </Right>
                    </>}


            </PopedImg>
        </>
    )
}

const Close = styled.div`
position: absolute;
right: 1vw;
top: -1.5vw;
@media(max-width:768px) {
    top: -6vw;
    right: 1vw;
}
`

const Left = styled.div`
position: absolute;
left: 2vw;
@media(max-width:768px){
    left: 0vw;
    display: none;

}
`
const Right = styled.div`
position: absolute;
right: 2vw;
@media(max-width:768px){
    right: 0vw;
    display: none;

}
`

const Container = styled.div`
position: fixed;
left: 0;
top: -6vh;
width: 100%;
padding: -30v;
opacity: 0.7;
height: 110%;
background-color: black;
z-index: 2;
`

const PopedImg = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 3;
position: fixed;
top:10vh;
left: 25vw;
width: 800px;
height:530px; 
opacity: 1;
background-color: white;
img{
    margin-top: 5vh;
    width: 550px;
    height: 413px;
}
text{
    margin: 20px;
    font-size: 40px;
}
@media(max-width:1024px) {
    width: 80vw;
    height: 50vh;
    top: 30vh;
    left: 10vw;
}
@media(max-width:768px){
    width: 80vw;
    height: 45vh;
    top: 30vh;
    left: 10vw;
    img{
        margin-top: 7vh;
        width: 70vw;
        height: 50vh;
    }
    text{
        font-size: 25px;
    }
}
`