import styled from "styled-components"

import Photos from "./Photos.js";

export default function Works() {
    return (
    < >
    <Container >
        <Photos/>
    </Container></>)
}

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
;

div{
    font-size: 40px;
    margin-top: 30px;
    color: black;
}
`