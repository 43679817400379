import styled from 'styled-components';
import { Element } from 'react-scroll';
import emailjs from 'emailjs-com';
import { useState } from 'react';
import { FormattedMessage } from "react-intl"


export default function Formulary() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [message, setMessage] = useState('');

        const formData ={
            from_name: name,
            email: email,
            telefone: telefone,
            message: message,
        };

        const handleSubmit = (e) => {
            e.preventDefault();
    
            emailjs.send(
                'service_fza2pgy',  
                'template_1b80fbh', 
                formData,
                '3_xPxPMUTAZGSIaRP'      
            )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Email enviado com sucesso!');
            }, (err) => {
                console.error('FAILED...', err);
                alert('Erro ao enviar email. Tente novamente.');
            });
        };



    return (
        <Element name='contact'>
        <Container>
            <h1><FormattedMessage id="app.contactus" defaultMessage="Contato"/></h1>
            <form onSubmit={handleSubmit} > 
                <Input type="text" placeholder="Name" onChange={(e)=>setName(e.target.value)} required />
                <Input type="email" placeholder="Email" onChange={(e)=>setEmail(e.target.value)} required />
                <Input type="tel" placeholder="Number" onChange={(e)=>setTelefone(e.target.value)}  required />
                <InputMessage placeholder="Message" onChange={(e)=>setMessage(e.target.value)} required />
                <button type='submit' >Enviar</button>
            </form>
        </Container></Element>
    );
}

const Input = styled.input`
    height: 4vh;
    width: 20vw;
    margin-top: 10px;
    @media (max-width: 768px) {
        width: 80vw;
    }
`;

const InputMessage = styled.textarea`
    box-sizing: border-box;
    resize: vertical;
    height: 10vh;
    width: 20.4vw;
    margin-top: 10px;
    padding: 10px;
    @media (max-width: 768px) {
        width: 82vw;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-left: 30%;
    justify-content: center;
    align-items: center;
    background-color: #5384E7;

    h1 {
        color: white;
        font-size: 30px;
        margin-top: 10px;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    button {
        width: 30%;
        height: 30px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    @media (max-width: 768px) {
        width: 100vw;
        margin-left: 0;

        h1 {
            font-size: 40px;
        }
    }
`;