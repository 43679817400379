import React,{createContext,useState} from "react";

const BackgroundContext = createContext()

export function BackgroundProvider({children}){
    const [ poped, setPoped ] = useState(false)
    const [ selectedPhoto, setSelectedPhoto ] = useState("")
    return(
        <BackgroundContext.Provider value={{poped,setPoped,selectedPhoto,setSelectedPhoto}}>
            {children}
        </BackgroundContext.Provider>
    )
} 
 
export default BackgroundContext