import styled from "styled-components"
import { Element } from 'react-scroll';
import { FormattedMessage } from "react-intl";

export default function Products() {
    return (
        <Element name="products">
        <Container>
           
            <text> <FormattedMessage id="app.products" defaultMessage="Nossos Produtos" /></text>
            <H1>
                <FormattedMessage id="app.products.text1" defaultMessage="Painéis Outdoor
                Indicados para locais abertos como shows, ações promocionais, fachadas de lojas em geral. Reprodução da imagem nítida e perfeita à partir de 3 m metros de distância, sendo resistentes a mudanças de clima e temperatura." />
            </H1>
            <H1>
                <FormattedMessage id="app.products.text2" defaultMessage="                Indicados para feiras em pavilhões fechados, stands corporativos, exposições, palestras, igrejas. Podem também ser usados em vitrines de lojas, festas, conferências, garantindo uma excelente visibilidade para sua marca."/>
            </H1>
            <H1><FormattedMessage id="app.products.text3" defaultMessage="Led Truck
                Indicados para eventos ao ar livre, permitindo total mobilidade ao destino desejado pelo cliente, podendo manter-se em um local fixo ou percorrer diversos pontos, de acordo com a necessidade da sua empresa." />                
            </H1>
        </Container></Element>
    )
}

const Container = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text {
        font-size: 40px;
        color: black;
    }
    @media (max-width: 768px) {
        width: 100vw;
    }
`;

const H1 = styled.h1`
    margin-top: 15px;
    color: black;
    width: 40%;
    font-size: 20px;
    font-weight: 200;
    @media (max-width: 768px) {
        width: 80%;
    }
`
