import React, { useContext } from 'react';
import styled from 'styled-components';
import logo from '../img/cartao.png';
import { FiInstagram } from "react-icons/fi";
import { ImFacebook2 } from "react-icons/im";
import { Link } from 'react-scroll';
import { FormattedMessage } from 'react-intl';
import { WrapperContext } from '../context/Wrapper';
import japao from '../img/bandeira-do-japao.webp';
import brasil from '../img/bandeiradobrasil-2-cke.webp';

const CustomSelect = ({ options }) => {
  const context = useContext(WrapperContext);

  return (
    <FlagContainer>
      {options.map(option => (
        <Flag
          key={option.value}
          src={option.icon}
          alt={option.alt}
          onClick={() => context.selectLang({ target: { value: option.value } })}
          isSelected={context.locale === option.value}
        />
      ))}
    </FlagContainer>
  );
};

export default function Header() {
  const context = useContext(WrapperContext);

  const options = [
    { value: 'jp-JP', icon: japao, alt: 'Japan' },
    { value: 'pt-BR', icon: brasil, alt: 'Brazil' },
  ];

  return (
    <Container>
      <Left>
        <Logo src={logo} />
      </Left>
      <Items>
        <Link to="home" offset={-120} smooth={true} href="#home"><FormattedMessage id='app.home' defaultMessage="Home" /> </Link>
        <Link to="about" offset={-150} smooth={true} href="#about"><FormattedMessage id="app.about" defaultMessage="Sobre Nós" /></Link>
        <Link to="products" offset={-150} smooth={true} href="#services"><FormattedMessage id='app.products' defaultMessage="Produtos" /></Link>
        <Link to="portfolio" offset={-130} smooth={true} href="#portfolio"><FormattedMessage id='app.portfolio' defaultMessage="Portfólio" /></Link>
        <Link to="empresa" offset={-140} smooth={true} href="#empresa"><FormattedMessage id='app.company' defaultMessage="Empresa" /></Link>
        <Link to="contact" smooth={true} href="#contact"><FormattedMessage id='app.contact' defaultMessage="Contato" /></Link>
      </Items>
      <Socials>
        <a href="https://www.instagram.com/ledvision.jp/" target="_blank" rel="noopener noreferrer">
          <FiInstagram color="blue" />
        </a>
        <a href="https://www.facebook.com/ledvisionrental" target="_blank" rel="noopener noreferrer">
          <ImFacebook2 color="blue" />
        </a>
      </Socials>
      <CustomSelect options={options} />

    </Container>
  );
}

const FlagContainer = styled.div`
  position: absolute;
  top: 80px;
  right: 2%;
  display: flex;
  align-items: center;
  @media(max-width:768px){
    top: 20px
  }
`;

const Flag = styled.img`
  width: 40px;
  height: 30px;
  margin-left: 10px;
  cursor: pointer;
  opacity: ${props => (props.isSelected ? 1 : 0.6)};
  border: ${props => (props.isSelected ? '2px solid blue' : 'none')};

  &:hover {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: fixed;
  z-index: 2;
  width: 100%;
  top: 0px;
  box-shadow: 0px 1px 0px gray;
  left: 0px;
  @media(max-width:768px){
    width: 100%;
    position: fixed;
    z-index: 1;
  }
`;

const Left = styled.div`
  position: relative;
  background-color: aliceblue;
  height: 120px;
  width: 100%;
  @media(max-width:768px){
    height: 15vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Logo = styled.img`
  width: 150px;
  height: 85px;
  margin-left: 20vw;
  margin-top: 17px;
  @media(max-width:1200px) {
    margin-left : 10%;
  }
  @media(max-width:950px){
    margin-left: 2%;
  }
  @media(max-width:768px){
    margin-bottom: 4%;
    margin-left: -30%;
    width: 50vw;
    height: 12vh;
  }
`;

const Items = styled.div`
  display: flex;
  position: absolute;
  width: 60vw;
  top: 40%;
  left: 40vw;
  a{
    color: black;
    margin-left: 1.5vw;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 1000;
  }
  @media(max-width:1200px) {
    left: 25vw;
  }
  @media(max-width:950px){
    left: 20vw;
  }
  @media(max-width:768px){
    display: none;
  }
`;

const Socials = styled.div`
  position: absolute;
  top: 20%;
  right: 2%;
  margin-right: 10px;
  margin-top: 10px;
  svg{
    margin-left: 10px;
    width: 30px;
    height: 30px;
  }
  @media(max-width:768px){
    display: flex;
    top: 60%;
    svg{
      margin-left: 10px;
      width: 30px;
      height: 30px;
    }
  }
`;