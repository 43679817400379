import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import portuguese from "../languages/pt.json";
import japonese from "../languages/jp.json";

export const WrapperContext = React.createContext();

const local = navigator.language;

let lang;
if (local === "ja" || local ==="ja-JA") {
    lang = japonese;
} else if(local === "pt-BR") {
    lang = portuguese;
}

const Wrapper = ({ children }) => {
    const [locale, setLocal] = useState(local);
    const [messages, setMessages] = useState(lang);

    function selectLang(e) {
        const newLocale = e.target.value;
        setLocal(newLocale);
        if (newLocale === "jp-JP") {
            setMessages(japonese);
            lang = japonese;
        } else {
            setMessages(portuguese);
            lang = portuguese;
        }
    }

    return (
        <WrapperContext.Provider value={{ locale, setLocal, selectLang }}>
            <IntlProvider locale={local} messages={lang}>
                {children}
            </IntlProvider>
        </WrapperContext.Provider>
    );
}
export default Wrapper;