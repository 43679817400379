import AboutUs from './components/AboutUs.js';
import Bottom from './components/Bottom.js';
import Carocel from './components/Carocel.js';
import Formulary from './components/Formulary.js';
import GlobalStyle from './components/GlobalStyle.js';
import Header from './components/Header.js';
import Highlight from './components/Highlight.js';
import Products from './components/Products.js';
import Profile from './components/Profile.js';
import Works from './components/Works.js';
import { BackgroundProvider } from './context/background.js';
import styled from 'styled-components';
import { useContext } from 'react';
import { WrapperContext } from './context/Wrapper.js';
import ReactGA from 'react-ga4';

function App() {
  ReactGA.initialize('G-QHYGZ3P8DG');

  return (
  <Container>
  <BackgroundProvider >
    <GlobalStyle />
    <Header />
    <Carocel />
    <AboutUs />
    <Products />
    <Highlight />
    <Works/>
    <Profile/>
    <Formulary />
    <Bottom />
  </BackgroundProvider>
  </Container>
  );
}

const Container = styled.div`
  margin-top: -2%;
  font-family: 'Roboto';
    /* background-image: url("https://ledwave.com.br/wp-content/uploads/2022/11/blackground_led-_1_-1.webp"); */
  background-color: aliceblue;
@media(max-width:768px) {
  overflow-y: hidden;
}
`

export default App;